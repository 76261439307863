/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import {
  SkeletonCategoriesListing,
  SkeletonProductsList,
} from "web/Layout/Common/SkeletonComponents";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import ListingHeader from "web/Layout/Listing/Common/Header";
import Products from "web/Layout/Listing/Common/Products";
import classes from "web/Layout/Listing/listing.scss";
import classesMobile from "web/Layout/Listing/listingMobile.scss";

import Filters from "web/Components/Common/Filters";

import getUniqueArrayElementsByField from "web/utils/data/transform/getUniqueArrayElementsByField";
import getUniqueElementsArray from "web/utils/data/transform/getUniqueElementsArray";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { sortCategoryOptions } from "web/constants/toolbar";

import { IAttribute } from "web/types/Attributes";
import type { Facets } from "web/types/Facets";
import type { Stats } from "web/types/Stats";
import {
  ISubscriptionFilterBenefitGroups,
  ISubscriptionItemMs,
} from "web/types/Subscription";

import { useAppContext } from "web/context/app";
import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import useBenefitsListingSearch from "./useBenefitsListingSearch";

const facetsHidden = ["mb_product_type_filter"];
const facetsHiddenMobile = ["mb_product_type_filter", "category_ids"];
interface IListingSearchProps {
  ids: number[];
  facets: Facets;
  stats: Stats;
  name: string;
  count: number;
  additionalParameters: string;
  categoriesPath: string;
  cgGroup1Path?: string;
  subscriptionItems?: ISubscriptionItemMs[];
}

const ListingSearch: FC<IListingSearchProps> = ({
  ids,
  facets,
  stats,
  name,
  count,
  additionalParameters,
  categoriesPath,
  cgGroup1Path,
  subscriptionItems = [],
}) => {
  const { isMobile } = useAppContext();

  const options = useMemo(() => {
    const filterCodes = [
      ...(stats?.map((stat) => stat.code) || []),
      ...(facets?.map((facet) => facet.code) || []),
    ];
    const filterFilteredCodes = filterCodes.filter(
      (item) => item && ["price"].indexOf(item) === -1
    );

    return {
      ids: getUniqueElementsArray(filterFilteredCodes),
    };
  }, [stats, facets]);

  const { loading, error, data } = useDataCachedAttributes(options);

  const attributes = useMemo(
    () =>
      isArrayHasItems(data)
        ? getUniqueArrayElementsByField<IAttribute>(data, "attribute_code")
        : [],
    [data]
  );

  const {
    loading: isLoadingFilteredSub,
    idsMapped,
    idsValidSubscriptions,
    idsWithoutSubscription,
    dataFiltered,
  } = useBenefitsListingSearch(ids, subscriptionItems);

  switch (true) {
    case !!error: {
      return <ErrorComponent />;
    }
    case !!loading || !data || data.length === 0: {
      return <SkeletonCategoriesListing />;
    }
    default: {
      const facetsHiddenByViewport = isMobile
        ? facetsHiddenMobile
        : facetsHidden;
      const facetsHiddenProcessed = facetsHiddenByViewport;
      const sortOptions = sortCategoryOptions;
      const rowClasses = isMobile ? classesMobile.row : classes.row;
      // eslint-disable-next-line no-nested-ternary
      const countProcessed = isArrayHasItems(subscriptionItems)
        ? isMobile
          ? idsWithoutSubscription.length
          : idsMapped.length
        : count;
      return (
        <>
          <ErrorBoundary>
            <ListingHeader
              name={name}
              count={countProcessed}
              // @ts-ignore
              sortOptions={sortOptions}
              // @ts-ignore
              stats={stats}
              facets={facets}
              facetsHidden={facetsHiddenProcessed}
              attributes={attributes}
            />
          </ErrorBoundary>
          <div className={`row ${rowClasses}`}>
            {!isMobile && (
              <aside className="col col-3">
                <ErrorBoundary>
                  <Filters
                    stats={stats}
                    facets={facets}
                    facetsHidden={facetsHiddenProcessed}
                    attributes={attributes}
                    withShiftedActiveFilters
                    ids={idsMapped}
                  />
                </ErrorBoundary>
              </aside>
            )}
            <div className="col col-9">
              <ErrorBoundary>
                {isLoadingFilteredSub ? (
                  <SkeletonProductsList />
                ) : (
                  <ProductsWrapper
                    ids={idsMapped}
                    additionalParameters={additionalParameters}
                    categoriesPath={categoriesPath}
                    cgGroup1Path={cgGroup1Path}
                    dataFilteredBenefits={dataFiltered}
                    idsValidSubscriptions={idsValidSubscriptions}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>
        </>
      );
    }
  }
};

interface IProductsWrapperProps {
  ids: number[];
  categoriesPath?: string;
  cgGroup1Path?: string;
  additionalParameters: string;
  dataFilteredBenefits: ISubscriptionFilterBenefitGroups;
  idsValidSubscriptions?: number[];
}

const ProductsWrapper: FC<IProductsWrapperProps> = ({
  ids,
  dataFilteredBenefits,
  additionalParameters,
  categoriesPath,
  cgGroup1Path,
  idsValidSubscriptions = [],
}) => {
  const { isMobile } = useAppContext();

  if (
    dataFilteredBenefits?.benefitGroups &&
    Object.keys(dataFilteredBenefits?.benefitGroups).length > 0
  ) {
    // filter out all subscription items on mobile
    const validIds = isMobile
      ? [...ids.filter((el) => !idsValidSubscriptions?.includes(el))]
      : ids;
    return (
      <Products
        ids={validIds}
        isAnixeTourism={false}
        additionalParameters={additionalParameters}
        categoriesPath={categoriesPath}
        cgGroup1Path={cgGroup1Path}
      />
    );
  }

  return (
    <Products
      ids={ids}
      additionalParameters={additionalParameters}
      categoriesPath={categoriesPath}
      cgGroup1Path={cgGroup1Path}
    />
  );
};

export default ListingSearch;
