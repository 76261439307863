import { useEffect, useState } from "react";

const MIN_KEYBOARD_HEIGHT = 100;

const useKeyboardVisible = () => {
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const keyboardVisible =
        window.screen.height - MIN_KEYBOARD_HEIGHT > window.innerHeight;
      setKeyboardVisible(keyboardVisible);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return keyboardVisible;
};

export default useKeyboardVisible;
