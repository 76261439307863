import { FC } from "react";
import { Link } from "react-router-dom";

import LanguageSwitcher from "web/Layout/Common/LanguageSwitcher";
import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import { IMPERSONATE_COOKIE_NAME } from "web/utils/system/cookie/const";
import removeCookie from "web/utils/system/cookie/removeCookie";

import urls from "web/constants/urls";

import type { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import Logout from "./Logout";
import defaultClasses from "./pointsLanguage.scss";

interface IPointsLanguageProps {
  totalPoints: number | undefined;
  onClick?: (arg: unknown) => void;
  ombRedirectLink?: Nullable<string>;
  ahrRedirectLink?: Nullable<string>;
}

const PointsLanguage: FC<PropsWithClasses<IPointsLanguageProps>> = ({
  classes = {},
  totalPoints,
  onClick,
  ombRedirectLink = "",
  ahrRedirectLink = "",
}) => {
  return (
    <section className={classes.root}>
      <header className={classes.header}>
        <h3 className={classes.title} data-t1="points_language_title">
          {__("Twoje punkty")}
        </h3>
        <Link
          to={urls.customerPoints}
          className={classes.link}
          onClick={onClick}
        >
          <Points points={totalPoints} />
        </Link>
      </header>
      <footer className={classes.footer}>
        {ombRedirectLink && (
          <a
            href={ombRedirectLink}
            className={classes.linkButton}
            data-t1="returnToOmb"
            onClick={() => removeCookie(IMPERSONATE_COOKIE_NAME)}
          >
            {__("Powrót do konsoli OMB")}
          </a>
        )}
        {ahrRedirectLink && (
          <a
            href={`${ahrRedirectLink}?logoutUrl=${window.location.origin}/customer/account/logout`}
            className={classes.linkButton}
            data-t1="switchToAhr"
            onClick={() => removeCookie(IMPERSONATE_COOKIE_NAME)}
          >
            {__("Przełącz na Administratora")}
          </a>
        )}
        <LanguageSwitcher />
        <Logout />
      </footer>
    </section>
  );
};

export default classify<PropsWithClasses<IPointsLanguageProps>>(defaultClasses)(
  PointsLanguage
);
