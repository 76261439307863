import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import { SkeletonCategoriesListing } from "web/Layout/Common/SkeletonComponents";
import GtmEvent from "web/Layout/Gtm/GtmEvent";
import ListingHeader from "web/Layout/Listing/Common/Header";
import __ from "web/Layout/Translations";

import usePrevious from "web/hooks/usePrevious/usePrevious";

import removeSpecialSigns from "web/utils/data/parser/string/removeSpecialSigns";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPageReferrer from "web/utils/system/GTM/getPageReferrer";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { searchName } from "web/constants/toolbar";
import { saveSearchTerm } from "web/constants/urls";

import { request } from "web/api";
import { useAppContext } from "web/context/app";
import { setFiltersLoading } from "web/features/app/appSlice";
import { getFilteredBenefitGroups } from "web/features/subscriptions/benefitGroupsThunks";
import useDataCachedIdsFacetsStats from "web/features/useDataCached/useDataCachedIdsFacetsStats";

import EmptyListing from "../EmptyListing";
import ListingType from "./ListingType";

const facetsHidden = ["mb_product_type_filter"];
const facetsHiddenMobile = ["mb_product_type_filter", "category_ids"];

interface IListingSearchContainerProps {
  activeFilters: string[];
  sortName: string;
  sortDirection: string;
  additionalParameters: string;
}

const ListingSearchContainer: FC<IListingSearchContainerProps> = ({
  activeFilters = [],
  sortName,
  sortDirection,
  additionalParameters = "",
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useAppContext();
  const { search, pathname } = useLocation();
  const { id: storeId } = useSelector((state) => state.app.storeConfig);

  const searchFromUrl = getSearchParameter({ name: searchName, search });

  const options = {
    variables: {
      search: removeSpecialSigns(searchFromUrl),
      facet: true,
      ...(sortName ? { sortBy: sortName } : {}),
      ...(sortDirection ? { sortOrder: sortDirection } : {}),
      ...(isArrayHasItems(activeFilters) ? { attributes: activeFilters } : {}),
      ...(additionalParameters
        ? {
            additionalParameters,
          }
        : {}),
    },
    context: {
      clientName: "ms",
    },
    fetchPolicy: "cache-and-network",
  };

  const { loading, error, data } = useDataCachedIdsFacetsStats({
    options: JSON.stringify(options),
    isSearch: true,
  });

  useEffect(() => {
    if (typeof setFiltersLoading === "function") {
      dispatch(setFiltersLoading(loading));
    }
  }, [loading]);

  const previousData = usePrevious(data);

  const gtmOptions = useMemo(
    () => ({
      cgroup1: __("Strona wyszukiwania"),
      cgroup2: __(`${searchFromUrl?.toLowerCase()}`),
      page_referrer: getPageReferrer({ search, pathname }),
    }),
    [search, pathname, searchFromUrl, getPageReferrer]
  );

  useEffect(() => {
    if (data?.subscription_items) {
      const subscriptionsSku =
        data?.subscription_items?.map(
          (el: { sku: string; id: string }) => el.sku
        ) || [];

      dispatch(getFilteredBenefitGroups({ skuArray: subscriptionsSku }));
    }
  }, [data]);

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <SkeletonCategoriesListing />;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    case (data && data.items_ids && data.items_ids.length === 0) ||
      // FEN-23325
      (data?.subscription_items?.length === data?.items_ids?.length &&
        isMobile): {
      const name = `${__("Wyniki wyszukiwania dla")}: ${searchFromUrl}`;
      request(`${saveSearchTerm}?a=${searchFromUrl}&q=${storeId}&t=${0}`);
      const facetsHiddenByViewport = isMobile
        ? facetsHiddenMobile
        : facetsHidden;

      return (
        <>
          <ListingHeader name={name} facetsHidden={facetsHiddenByViewport} />
          <EmptyListing />
          <GtmEvent options={gtmOptions} withUrl />
        </>
      );
    }
    default: {
      const {
        items_ids: ids,
        facets,
        stats,
        subscription_items,
      } = data || {
        items_ids: [],
        facets: [],
        stats: [],
        subscription_items: [],
      };
      const name = `${__("Wyniki wyszukiwania dla")}: ${searchFromUrl}`;

      if (previousData !== data) {
        request(
          `${saveSearchTerm}?a=${searchFromUrl}&q=${storeId}&t=${(
            ids?.length + 5678
          ).toString(16)}`
        );
      }

      return (
        <>
          <ListingType
            ids={ids}
            stats={stats}
            facets={facets}
            name={name}
            count={ids?.length}
            additionalParameters={additionalParameters}
            categoriesPath={gtmOptions.cgroup2}
            cgGroup1Path={gtmOptions.cgroup1}
            subscriptionItems={subscription_items}
          />
          <GtmEvent options={gtmOptions} withUrl />
        </>
      );
    }
  }
};

export default ListingSearchContainer;
