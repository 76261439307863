import { FC, memo } from "react";

import BoxIcon from "web/assets/icons/box.svg";
import HeartIcon from "web/assets/icons/heart-empty.svg";
import HomeIcon from "web/assets/icons/home.svg";
import MenuIcon from "web/assets/icons/nav.svg";
import UserIcon from "web/assets/icons/user.svg";

import useIsNativeApp from "web/hooks/useIsNativeApp";
import useKeyboardVisible from "web/hooks/useKeyboardVisible";

import urls from "web/constants/urls";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import Additional from "./Additional";
import Navigation from "./Navigation";
import PanelItem from "./PanelItem";
import defaultClasses from "./navigationPanel.scss";

const items = [
  {
    id: "home",
    link: urls.home,
    name: "Strona główna",
    icon: HomeIcon,
  },
  {
    id: "navigation",
    link: urls.navigation,
    name: "Nawigacja",
    icon: MenuIcon,
  },
  {
    id: "customer",
    link: urls.customer,
    name: "Panel klienta",
    icon: UserIcon,
  },
  {
    id: "productsQuickList",
    link: urls.productsQuickList,
    name: "Podręczna lista produktów",
    icon: BoxIcon,
  },
  {
    id: "customerWishlist",
    link: urls.customerWishlist,
    name: "Ulubione",
    icon: HeartIcon,
  },
] as const;

export type NavigationItem = (typeof items)[number];

const NavigationPanel: FC<PropsWithClasses> = memo(({ classes = {} }) => {
  const isKeyboardVisible = useKeyboardVisible();
  const isNativeApp = useIsNativeApp();

  return (
    <div className={classes.root}>
      <Additional />
      <Navigation />
      {(!isKeyboardVisible || !isNativeApp) && (
        <nav className={classes.nav}>
          {items.map((item) => (
            <PanelItem key={item.id} item={item} />
          ))}
        </nav>
      )}
    </div>
  );
});

export default classify(defaultClasses)(NavigationPanel);
