// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHeader-root-odG{position:sticky;top:0;width:100%;z-index:100;background-color:#fff}.mainHeader-rootHigherIndex-pd7{z-index:101}.mainHeader-header-bIo{padding:15px;display:flex;align-items:center;justify-content:space-between}.mainHeader-logo-wog{display:block;width:100%;max-width:120px}@media print{.mainHeader-root-odG{display:none}}`, "",{"version":3,"sources":["webpack://./web/Layout/MainHeader/Mobile/mainHeader.scss"],"names":[],"mappings":"AAIuB,qBACrB,eAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CAGF,gCACE,WAAA,CAGF,uBACE,YAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,qBACE,aAAA,CACA,UAAA,CACA,eAAA,CAGF,aACE,qBACE,YAAA,CAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: sticky;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n  background-color: white;\n}\n\n.rootHigherIndex {\n  z-index: 101;\n}\n\n.header {\n  padding: 15px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.logo {\n  display: block;\n  width: 100%;\n  max-width: 120px;\n}\n\n@media print {\n  .root {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `mainHeader-root-odG`,
	"rootHigherIndex": `mainHeader-rootHigherIndex-pd7`,
	"header": `mainHeader-header-bIo`,
	"logo": `mainHeader-logo-wog`
};
export default ___CSS_LOADER_EXPORT___;
